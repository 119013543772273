import React from 'react';

const SupportItem = () => {
    return(
        <section className="support_help_area sec_pad">
            <div className="container">
                <h2 className="f_p f_size_30 l_height50 f_600 t_color text-center wow fadeInUp mb_60">Contattaci per avere un preventivo</h2>
                <div className="d-flex">
                    <div className="support_item">
                        <img src={require ('../../img/new-home/ticket-support.png')} alt=""/>
                        <h4>Contattaci telefonicamente</h4>
                        <a href="tel:+393292343923" className="software_banner_btn btn_submit f_size_15 f_500">+393292343923</a>
                    </div>
                    <div className="support_item">
                        <img src={require ('../../img/new-home/email.png')} alt=""/>
                        <h4>Scrivici una mail</h4>
                        <a href="mailto:support@parkingless.com" className="software_banner_btn btn_submit f_size_15 f_500">support@parkingless.com</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SupportItem;

